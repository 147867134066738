export function jobStateToHumanReadableName(jobState) {
    switch (jobState.toUpperCase()) {
        case 'AWAITING':
            return 'pending jobs';
        case 'SCHEDULED':
            return 'scheduled jobs';
        case 'ENQUEUED':
            return 'enqueued jobs';
        case 'PROCESSING':
            return 'jobs in progress';
        case 'PROCESSED':
            return 'jobs in progress';
        case 'SUCCEEDED':
            return 'succeeded jobs';
        case 'FAILED':
            return 'failed jobs';
        case 'DELETED':
            return 'deleted jobs';
        default:
            return 'Unknown state'
    }
}

export function jobStateToHumanReadableTitle(jobState) {
    switch (jobState.toUpperCase()) {
        case 'AWAITING':
            return 'Jobs waiting for other jobs to succeed';
        case 'SCHEDULED':
            return 'Scheduled jobs';
        case 'ENQUEUED':
            return 'Enqueued jobs';
        case 'PROCESSING':
            return 'Jobs being processed';
        case 'PROCESSED':
            return 'Jobs being processed';
        case 'SUCCEEDED':
            return 'Succeeded jobs';
        case 'FAILED':
            return 'Failed jobs';
        case 'DELETED':
            return 'Deleted jobs';
        default:
            return 'Unknown state'
    }
}

export function batchJobStateToHumanReadableTitle(jobState) {
    switch (jobState.toUpperCase()) {
        case 'AWAITING':
            return 'Batch jobs waiting for other jobs to succeed';
        case 'SCHEDULED':
            return 'Scheduled batch jobs';
        case 'ENQUEUED':
            return 'Enqueued batch jobs';
        case 'PROCESSING':
            return 'Batch jobs being processed';
        case 'PROCESSED':
            return 'Batch jobs being processed';
        case 'SUCCEEDED':
            return 'Succeeded batch jobs';
        case 'FAILED':
            return 'Failed batch jobs';
        case 'DELETED':
            return 'Deleted batch jobs';
        default:
            return 'Unknown state'
    }
}