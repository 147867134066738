import React from 'react';
import {createMuiTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import {Redirect, Route, Switch} from 'react-router-dom';
import TopAppBar from "./TopAppBar";
import Overview from "../components/overview/overview";
import Servers from "../components/servers/servers";
import RecurringJobs from "../components/recurring-jobs/recurring-jobs";
import WithSidebar from "../components/utils/with-sidebar";
import JobView from "../components/jobs/job-view";
import JobsView from "../components/jobs/jobs-view";
import Sidebar from "../components/jobs/sidebar";
import BatchAdmin from "../components/batch-jobs/BatchAdmin";
import GithubStarPopup from "../components/utils/github-star-popup";
import {getPublicUrl, prefixWithPublicUrl} from "../utils/helper-functions";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginTop: 56
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const AdminUI = function () {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#000'
            }
        }
    });
    const classes = useStyles();

    return (
        <MuiThemeProvider theme={theme}>
            {window.trialExpirationDate && window.trialExpirationDate < new Date()
                ?
                <main className={classes.content}>
                    <div className={classes.paper}>
                        <h1>Your JobRunr Pro Trial has expired</h1>
                        <p>
                            Please <a href="mailto:hello@jobrunr.io?subject=JobRunr%20Pro%20License&body=Hi%2C%0D%0A%0D%0Awe%27re%20interested%20in%20a%20JobRunr%20Pro%20License.%0D%0A%0D%0AOur%20company%20is%3A%20%3Cplease%20enter%20your%20company%20name%20here%3E%0D%0AOur%20VAT%20number%20is%3A%20%3Cplease%20enter%20your%20VAT%20number%20here%3E%0D%0AOur%20address%20is%3A%20%3Cplease%20enter%20your%20address%20here%3E%0D%0A%0D%0ARegards!">buy</a>&nbsp;
                            a JobRunr Pro License to continue using it.
                        </p>
                        <p>By doing so, you help open-source software development and <a href="https://www.jobrunr.io/en/about/#eco-friendly-software" target="_blank" rel="noreferrer">our planet</a>.</p>
                    </div>
                </main>
                :
                <div className={classes.root}>
                    <GithubStarPopup/>
                    <TopAppBar/>
                    <main className={classes.content}>
                        <Switch>
                            <Route path={prefixWithPublicUrl('/overview')} component={Overview}/>
                            <Route path={prefixWithPublicUrl('/jobs/:id')} component={WithSidebar(Sidebar, JobView)}/>
                            <Route path={prefixWithPublicUrl('/jobs')} component={WithSidebar(Sidebar, JobsView)}/>
                            <Route path={prefixWithPublicUrl('/batch-jobs')} component={BatchAdmin}/>
                            <Route path={prefixWithPublicUrl('/recurring-jobs')} component={RecurringJobs}/>
                            <Route path={prefixWithPublicUrl('/servers')} component={Servers}/>
                            <Redirect from={getPublicUrl()} to={prefixWithPublicUrl('/overview')}/>
                        </Switch>
                    </main>
                </div>
            }
        </MuiThemeProvider>
    );
};

export default AdminUI;
