import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {prefixWithContextPath} from "../../../utils/helper-functions";
import {FormControlLabel, Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {useHistory, useLocation} from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(theme => ({
    jobActions: {
        margin: '1rem 0 -0.5rem 1rem',
    },
    selectAllJobs: {
        marginLeft: '1rem',
    }
}));

export const Actions = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const selectedState = props.selectedState;
    const selectedJobs = props.selectedJobs;
    const actionsDisabled = selectedJobs.length < 1;
    const showSelectAllJobsNotification = props.allJobsSelected && selectedJobs.length >= props.pageSize;

    const [allJobsSelected, setAllJobsSelected] = React.useState(false);
    const [apiStatus, setApiStatus] = React.useState(null);

    const urlSearchParams = new URLSearchParams(location.search);
    const action = urlSearchParams.get('action');

    React.useEffect(() => {
        if(action) {
            if(action === 'requeue') {
                setApiStatus({type: 'requeue', severity: 'success', message: 'Successfully requeued jobs'});
            } else if(action === 'delete') {
                setApiStatus({type: 'delete', severity: 'success', message: 'Successfully deleted jobs'});
            }
        }
    }, [action]);

    const refreshPage = (action) => {
        urlSearchParams.set('action', action);
        history.replace(`?${urlSearchParams.toString()}`);
    };

    const handleCloseAlert = () => {
        setApiStatus(null);
    };

    const queueSelectedJobs = () => {
        fetch(getJobApiUrlForSelectedJobs('/api/jobs/requeue'), {
            method: 'POST',
        })
            .then(res => {
                if (res.status === 204) {
                    refreshPage('requeue');
                } else {
                    setApiStatus({type: 'requeue', severity: 'error', message: 'Error requeueing jobs'});
                }
            })
            .catch(error => console.error(error));
    };

    const deleteSelectedJobs = () => {
        fetch(getJobApiUrlForSelectedJobs('/api/jobs'), {
            method: 'DELETE',
        })
            .then(res => {
                if (res.status === 204) {
                    refreshPage('delete');
                } else {
                    setApiStatus({type: 'delete', severity: 'error', message: 'Error deleting jobs'});
                }
            })
            .catch(error => console.error(error));
        };

    const getJobApiUrlForSelectedJobs = (url) => {
        if(allJobsSelected) {
            if(location.pathname.includes('/batch-jobs')) {
                const selectedChildStateTabKey = Array.from(urlSearchParams.keys()).find(key => key.startsWith('processing-child-job-state'));
                let apiSearchParams = new URLSearchParams();
                apiSearchParams.set('awaitingOn', location.pathname.substring(location.pathname.indexOf('/batch-jobs') + '/batch-jobs'.length + 1));
                apiSearchParams.set('state', urlSearchParams.has(selectedChildStateTabKey) ? urlSearchParams.get(selectedChildStateTabKey) : 'ENQUEUED');
                return prefixWithContextPath(`${url}?${apiSearchParams.toString()}`);
            } else {
                const apiSearchParams = new URLSearchParams(location.search);
                return prefixWithContextPath(`${url}?${apiSearchParams.toString()}`);
            }
        } else {
            const jobIds = selectedJobs.map(job => job.id).join();
            return prefixWithContextPath(`${url}?ids=${jobIds}`);
        }
    }

    return (
        <>
            <ButtonGroup className={classes.jobActions} disabled={actionsDisabled}>
                {!(['DELETED'].includes(selectedState)) &&
                    <Button variant="outlined" color="primary" onClick={deleteSelectedJobs}>
                        Delete
                    </Button>
                }
                {!(['AWAITING', 'ENQUEUED'].includes(selectedState)) &&
                    <Button variant="outlined" color="primary" onClick={queueSelectedJobs}>
                        {selectedState === 'SCHEDULED' ? 'Enqueue' : 'Requeue'}
                    </Button>
                }
            </ButtonGroup>
                {showSelectAllJobsNotification &&
                    <FormControlLabel
                        style={{marginLeft: '1rem'}}
                        control={<Checkbox checked={allJobsSelected} onChange={(evt) => setAllJobsSelected(evt.target.checked)} name="selectAllJobs" />}
                        label="Select all jobs on other pages too?"
                    />
                }

            {apiStatus &&
                <Snackbar open={true} autoHideDuration={3000} onClose={handleCloseAlert}>
                    <Alert severity={apiStatus.severity}>
                        {apiStatus.message}
                    </Alert>
                </Snackbar>
            }
        </>
    )
}