/**
 * EventSource that automatically closes the EventSource if the tab is not active anymore.
 */

class SmartEventSource {

    constructor(url) {
        this._url = url;
        this._eventListeners = new Map();
        document.addEventListener('visibilitychange', this._closeAndReopenOnVisibilityChange);
    }

    set onmessage(func) {
        this._onmessage = func;
        this._closeAndReopenOnVisibilityChange();
    }

    addEventListener(type, func) {
        this._eventListeners.set(type, func);
        this._eventSource?.addEventListener(type, func);
    }

    close() {
        this._eventSource?.close();
        this._eventListeners.clear();
        document.removeEventListener('visibilitychange', this._closeAndReopenOnVisibilityChange);
    }

    _closeAndReopenOnVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
            this._eventSource?.close();
            this._eventSource = null;
        } else {
            this._eventSource = new EventSource(this._url);
            this._eventSource.addEventListener('close', e => this._eventSource.close());
            if(this._onmessage) {
                this._eventSource.onmessage = this._onmessage;
            }
            this._eventListeners.forEach((func, type) => this._eventSource.addEventListener(type, func));
        }
    }
}

export default SmartEventSource;