import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import TimeAgo from "react-timeago/lib";
import {DatabaseCheck} from "mdi-material-ui";


const useStyles = makeStyles(theme => ({
    primaryHeading: {
        textTransform: "none",
        lineHeight: "inherit"
    },
    secondaryHeading: {
        alignSelf: 'center',
        marginLeft: 'auto'
    },
    alert: {
        padding: 0
    },
    info: {
        color: "rgb(13, 60, 97)",
        backgroundColor: "rgb(196, 227, 250)",
        minHeight: 56,
        '& div.MuiAlert-icon': {
            color: 'rgb(13, 60, 97)',
            backgroundColor: 'rgb(196, 227, 250)',
        },
        '& div.MuiAlert-standardInfo': {
            color: 'rgb(13, 60, 97)',
            backgroundColor: 'rgb(196, 227, 250)',
        },
    }
}));


const EnqueuedChildJobs = (props) => {
    const classes = useStyles();
    const jobState = props.jobState;
    const enqueuedIcon = <DatabaseCheck />

    return (
        <Accordion>
            <AccordionSummary
                className={classes.info}
                id="enqueued-panel-header"
            >
                <Alert className={classes.alert} severity="info" icon={enqueuedIcon}>
                    <Typography className={classes.primaryHeading} variant="h6">
                        Enqueueing child jobs
                    </Typography>
                </Alert>
                <Typography className={classes.secondaryHeading}><TimeAgo date={new Date(jobState.createdAt)}/></Typography>
            </AccordionSummary>
        </Accordion>
    )
};

export default EnqueuedChildJobs;