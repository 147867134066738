import React from 'react';
import {Link} from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import {Schedule} from '@material-ui/icons';
import {AlertCircleOutline, Check, Cogs, Delete, LockClock, TimerSand} from 'mdi-material-ui';
import statsState from '../../StatsStateContext';
import queuesState from '../../QueuesStateContext';
import {makeStyles} from "@material-ui/core/styles";
import {prefixWithPublicUrl} from "../../utils/helper-functions";

const categories = [
    {name: "awaiting", state: "AWAITING", label: "Pending", icon: <LockClock/>},
    {name: "scheduled", state: "SCHEDULED", label: "Scheduled", icon: <Schedule/>},
    {name: "enqueued", state: "ENQUEUED", label: "Enqueued", icon: <TimerSand/>},
    {name: "processing", state: "PROCESSING", label: "Processing", icon: <Cogs/>},
    {name: "succeeded", state: "SUCCEEDED", label: "Succeeded", icon: <Check/>},
    {name: "failed", state: "FAILED", label: "Failed", icon: <AlertCircleOutline/>},
    {name: "deleted", state: "DELETED", label: "Deleted", icon: <Delete/>},
];

const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const Sidebar = () => {
    const classes = useStyles();

    const queues = queuesState.useQueues();
    const [stats, setStats] = React.useState(statsState.getStats());
    React.useEffect(() => {
        statsState.addListener(setStats);
        return () => statsState.removeListener(setStats);
    }, [])

    return (
        <List component="div">
            {categories.map(({name, state, label, icon}) => (
                <React.Fragment key={label}>
                    <ListItem id={`${name}-menu-btn`} button title={label}
                              component={Link} to={ prefixWithPublicUrl(`/jobs?state=${state}`)}>
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText primary={label}/>
                        <Chip label={stats[name]}/>
                    </ListItem>
                    {name === 'enqueued' && queues.length > 1 &&
                    <List component="div">
                        {queues.map((queue, index) => (
                            <ListItem id={`${index}-queue-menu-btn`} button key={queue} className={classes.nested} component={Link} to={prefixWithPublicUrl(`/jobs?state=ENQUEUED&priority=${index}`)}>
                                <ListItemIcon><Cogs/></ListItemIcon>
                                <ListItemText primary={queue}/><Chip label={stats['enqueuedQueue' + index]}/>
                            </ListItem>
                        ))}
                    </List>
                    }
                </React.Fragment>
            ))}
        </List>
    );
};

export default Sidebar;