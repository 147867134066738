import React from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import {Link as UILink} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TimeAgo from "react-timeago/lib";
import {makeStyles} from '@material-ui/core/styles';
import LoadingIndicator from "../LoadingIndicator";
import {prefixWithPublicUrl} from "../../utils/helper-functions";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import {allItemsSelected, selectAllItems, selectedItems, selectItem} from "../utils/job-selections";
import {Actions} from "./actions/actions";
import {capitalize} from "../utils/string-utils";
import {jobStateToHumanReadableName} from "../utils/job-utils";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        //maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    content: {
        width: '100%',
    },
    table: {
        width: '100%',
    },
    jobActions: {
        margin: '1rem 0 -0.5rem 1rem',
    },
    pagination: {
        marginTop: '0.5rem',
    },
    noItemsFound: {
        padding: '1rem'
    },
    idColumn: {
        maxWidth: 0,
        width: '20%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    jobNameColumn: {
        width: '60%'
    },
    inline: {
        display: 'inline',
    },
}));

const JobsTable = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const isLoading = props.isLoading;
    const jobPage = props.jobPage;
    const jobState = props.jobState;
    const [jobs, setJobs] = React.useState(jobPage.items.map(job => ({...job, selected: false})));

    let column = capitalize(jobState);
    let columnFunction = (job) => job.jobHistory[job.jobHistory.length - 1].createdAt;
    switch (jobState) {
        case 'AWAITING':
            column = 'Created';
            break;
        case 'SCHEDULED':
            columnFunction = (job) => job.jobHistory[job.jobHistory.length - 1].scheduledAt;
            break;
        case 'PROCESSING':
            column = "Started";
            break;
        default:
        // code block
    }

    const handleChangePage = (event, newPage) => {
        let urlSearchParams = new URLSearchParams(location.search);
        urlSearchParams.delete('action');
        urlSearchParams.set("page", newPage);
        history.push(`?${urlSearchParams.toString()}`);
    };

    const handleChangeRowsPerPage = (event) => {
        let urlSearchParams = new URLSearchParams(location.search);
        urlSearchParams.delete('action');
        urlSearchParams.set("itemsPerPage", event.target.value);
        history.push(`?${urlSearchParams.toString()}`);
    }

    const jobsMatchingFiltersWithoutState = () => {
        const toUrl = (state) => {
            let urlSearchParams = new URLSearchParams(location.search);
            urlSearchParams.set("state", state);
            return <UILink component="button" variant="body1" style={{color: '#26a8ed'}} onClick={() => history.push(`?${urlSearchParams.toString()}`)}>{state}</UILink>
        }

        if(props.jobPageWithoutState && props.jobPageWithoutState.total > 0) {
            const matchingStates = [...new Set(props.jobPageWithoutState.items.map(job => job.jobHistory[job.jobHistory.length - 1].state))];
            return <> - there are jobs matching your filters with the
                {matchingStates.map((state, index) => (
                    <React.Fragment key={index}>{matchingStates[index - 1] ? matchingStates[index + 1] ? ', ' : ' and ' : ''} {toUrl(state)}</React.Fragment>
                ))}&nbsp;state.
            </>
        }
        return <></>;
    }

    return (
        <> {isLoading
            ? <LoadingIndicator/>
            : <> {jobPage.items < 1
                ? <>
                    <Typography id="no-jobs-found-message" variant="body1" className={classes.noItemsFound}>No {jobStateToHumanReadableName(jobState)} found{jobsMatchingFiltersWithoutState()}</Typography>
                </>
                : <>
                    <Grid item xs={12} container>
                        <Grid item xs={6}>
                            <Actions selectedState={jobState} allJobsSelected={allItemsSelected(jobs)} selectedJobs={selectedItems(jobs)} pageSize={jobPage.limit} />
                        </Grid>
                        <Grid item xs={6}>
                            <TablePagination
                                id="jobs-table-pagination-header"
                                component="div"
                                className={classes.pagination}
                                rowsPerPageOptions={[20, 50, 100]}
                                count={jobPage.total}
                                rowsPerPage={jobPage.limit}
                                page={jobPage.currentPage}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer>
                        <Table id="jobs-table" className={classes.table} aria-label="jobs table">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox checked={allItemsSelected(jobs)} onClick={evt => selectAllItems(evt, setJobs, jobs)}/>
                                    </TableCell>
                                    <TableCell className={classes.idColumn}>Id</TableCell>
                                    <TableCell className={classes.jobNameColumn}>Job name</TableCell>
                                    <TableCell>{column}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {jobs.map(job => (
                                    <TableRow key={job.id}>
                                        <TableCell padding="checkbox">
                                            <Checkbox checked={job.selected} onClick={() => selectItem(job, setJobs, jobs)}/>
                                        </TableCell>
                                        <TableCell component="th" scope="row" className={classes.idColumn}>
                                            <Link to={{
                                                pathname: prefixWithPublicUrl(`/jobs/${job.id}`),
                                                job: job
                                            }}>{job.id}</Link>
                                        </TableCell>
                                        <TableCell>
                                            <Link to={{
                                                pathname: prefixWithPublicUrl(`/jobs/${job.id}`),
                                                job: job
                                            }}>{job.jobName}</Link>
                                        </TableCell>
                                        <TableCell>
                                            <Link to={{pathname: prefixWithPublicUrl(`/jobs/${job.id}`), job: job}}>
                                                <TimeAgo date={new Date(columnFunction(job))} title={new Date(columnFunction(job)).toString()}/>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        id="jobs-table-pagination"
                        component="div"
                        rowsPerPageOptions={[20, 50, 100]}
                        count={jobPage.total}
                        rowsPerPage={jobPage.limit}
                        page={jobPage.currentPage}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </>
            }
            </>
        }
        </>
    );
}

export default JobsTable;