import {prefixWithContextPath} from "./utils/helper-functions";

class Queues {
    constructor() {
        this._data = {};
        this._data.queues = [];
    }

    setQueues(queues) {
        this._data.queues = queues;
    }

    getQueues() {
        return this._data.queues;
    }

    useQueues() {
        return this.getQueues();
    }
}

const queuesState = new Queues();
Object.freeze(queuesState);

fetch(prefixWithContextPath(`/api/queues`))
    .then(res => res.json())
    .then(response => {
        queuesState.setQueues(response);
    })
    .catch(error => console.log(error));

export default queuesState;