import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Alert, AlertTitle} from '@material-ui/lab';
import {Button} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    metadata: {
        display: 'flex',
    },
    alert: {
        width: '100%',
        marginBottom: '2rem',
    },
    alertTitle: {
        lineHeight: 1,
        margin: 0
    }
}));

const JobRunrProTrialNotification = (props) => {
    const classes = useStyles();

    return (
        <>
            {window.trialExpirationDate &&
                <div className={classes.metadata}>
                    <Alert className={classes.alert} severity="info" action={
                        <Button color="secondary" variant="contained" href="mailto:hello@jobrunr.io?subject=JobRunr%20Pro%20License&body=Hi%2C%0D%0A%0D%0Awe%27re%20interested%20in%20a%20JobRunr%20Pro%20License.%0D%0A%0D%0AOur%20company%20is%3A%20%3Cplease%20enter%20your%20company%20name%20here%3E%0D%0AOur%20VAT%20number%20is%3A%20%3Cplease%20enter%20your%20VAT%20number%20here%3E%0D%0AOur%20address%20is%3A%20%3Cplease%20enter%20your%20address%20here%3E%0D%0A%0D%0ARegards!">
                            BUY JOBRUNR PRO NOW
                        </Button>
                    }>
                        <AlertTitle><h4 className={classes.alertTitle}>JobRunr Pro Trial</h4></AlertTitle>
                        You are running a JobRunr trial that is active until {window.trialExpirationDate.toLocaleDateString()}. Do not hesitate to <a href="mailto:hello@jobrunr.io?subject=JobRunr%20Pro%20Trial%20License">reach out</a> if you have any questions or remarks about your trial.<br/>
                    </Alert>
                </div>
            }
        </>
    );
};

export default JobRunrProTrialNotification;