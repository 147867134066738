import SmartEventSource from "./utils/SmartEventSource";
import {prefixWithContextPath} from "./utils/helper-functions";

class ServersState {
    constructor() {
        this._listeners = [];
        this._data = { servers: [{firstHeartbeat: undefined, serverTags: []}] };
    }

    setServers(servers) {
        this._data.servers = servers;
        this._listeners.forEach(listener => listener(servers));
    }

    getServers() {
        return this._data.servers;
    }

    addListener(listener) {
        this._listeners.push(listener);
        if(this._data.servers) {
            listener(this._data.servers);
        }
    }

    removeListener(listener) {
        const index = this._listeners.indexOf(listener);
        if (index > -1) {
            this._listeners.splice(index, 1);
        }
    }
}

const serversState = new ServersState();
Object.freeze(serversState);

fetch(prefixWithContextPath(`/api/servers`))
    .then(res => res.json())
    .then(response => {
        serversState.setServers(sortServers(response));
    })
    .catch(error => console.log(error));

const _eventSource = new SmartEventSource(prefixWithContextPath("/sse/servers"));
_eventSource.onmessage = e => serversState.setServers(sortServers(JSON.parse(e.data)));

const sortServers = (servers) => {
    servers.sort((a, b) => a.firstHeartbeat > b.firstHeartbeat)
    return servers;
}

export default serversState;