import Alert from "@material-ui/lab/Alert";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TimeAgo from "react-timeago/lib";


const useStyles = makeStyles(() => ({
    alert: {
        fontSize: '1rem'
    }
}));


const SucceededNotification = (props) => {
    const classes = useStyles();

    const job = props.job;
    const deleteDate = job.deleteAt;

    return (
        <>
            {deleteDate &&
            <Grid item xs={12}>
                <Paper>
                    <Alert severity="info" className={classes.alert}>
                        <strong>This job has succeeded.</strong> It will automatically go to the deleted state in <TimeAgo
                        date={deleteDate} title={deleteDate.toString()}/>.
                    </Alert>
                </Paper>
            </Grid>
            }
        </>
    )
};

export default SucceededNotification;