import Grid from "@material-ui/core/Grid";
import Highlight from "react-highlight";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import queuesState from "../../QueuesStateContext";

const useStyles = makeStyles(theme => ({
    codeContent: {
        marginTop: 0,
        paddingTop: "0 !important",
        '& > pre': {
            marginTop: 0,
        }
    },
    detailsContent: {
        marginLeft: '-12px',
        marginBottom: '24px',
        backgroundColor: '#fff',
        '& > dl': {
            margin: '0 0 0.5em 0'
        },
        '& > dl dt': {
            display: 'inline-block',
            width: '30%',
            textAlign: 'right'
        },
        '& > dl dd': {
            display: 'inline',
            marginLeft: '0.8em',
            width: '70%'
        }
    }
}));

const JobCode = (props) => {
    const { job } = props;
    const classes = useStyles();

    const queues = queuesState.useQueues();

    const fqClassName = job.jobDetails.className;
    const className = job.jobDetails.className.substring(job.jobDetails.className.lastIndexOf(".") + 1);
    const staticFieldName = job.jobDetails.staticFieldName;
    const methodName = job.jobDetails.methodName;
    const parameters = job.jobDetails.jobParameters.map(jobParameter => jobParameter.object).join(", ")

    let totalFunction = className;
    if(staticFieldName) {
        totalFunction += "." + staticFieldName;
    }
    totalFunction += "." + methodName;
    totalFunction += "(" + parameters + ")";


    const code = `
    import ${fqClassName};
    
    ${totalFunction};
    `;

    return (
        <Grid item xs={12}>
            <Grid container spacing={3}>
                <Grid item xs={9} className={classes.codeContent}>
                    <Highlight className='language-java'>
                        {code}
                    </Highlight>
                </Grid>
                <Grid item xs={3} className={classes.detailsContent}>
                    <dl><dt>Priority:</dt><dd>{queues[job.priority]}</dd></dl>
                    <dl><dt>Server tag:</dt><dd>{job.serverTag}</dd></dl>
                    {job.mutex &&
                        <dl><dt>Mutex:</dt><dd>{job.mutex}</dd></dl>
                    }
                </Grid>
            </Grid>
        </Grid>
    );


};

export default JobCode;