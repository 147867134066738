import React from 'react';
import {Link} from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Schedule} from "@material-ui/icons";
import {AlertCircleOutline, Check, Cogs, Delete, LockClock, TimerSand} from "mdi-material-ui";
import {prefixWithPublicUrl} from "../../utils/helper-functions";

const categories = [
    {name: "awaiting", state: "AWAITING", label: "Pending", icon: <LockClock/>},
    {name: "scheduled", state: "SCHEDULED", label: "Scheduled", icon: <Schedule/>},
    {name: "enqueued", state: "ENQUEUED", label: "Enqueued", icon: <TimerSand/>},
    {name: "processing", state: "PROCESSED", label: "Processing", icon: <Cogs/>},
    {name: "succeeded", state: "SUCCEEDED", label: "Succeeded", icon: <Check/>},
    {name: "failed", state: "FAILED", label: "Failed", icon: <AlertCircleOutline/>},
    {name: "deleted", state: "DELETED", label: "Deleted", icon: <Delete/>},
];

const BatchSidebar = () => {
    return (
            <List component="div">
                {categories.map(({name, state, label, icon}) => (
                    <React.Fragment key={label}>
                        <ListItem id={`${name}-menu-btn`} button title={label}
                                  component={Link} to={ prefixWithPublicUrl(`/batch-jobs?state=${state}`)}>
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText primary={label}/>
                        </ListItem>
                    </React.Fragment>
                ))}
            </List>
    );
};

export default BatchSidebar;