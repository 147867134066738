export const selectItem = (selectedItem, setItems, allItems) => {
    setItems(allItems.map(item => {
        if (item.id === selectedItem.id) {
            return ({...item, selected: !item.selected});
        } else {
            return item;
        }
    }))
};

export const selectAllItems = (event, setItems, allItems) => {
    if (event.target.checked) {
        setItems(allItems.map(item => ({...item, selected: true})));
    } else {
        setItems(allItems.map(item => ({...item, selected: false})));
    }
};

export const allItemsSelected = (allItems) => {
    return allItems.every(item => item.selected);
};

export const anyItemSelected = (allItems) => {
    return allItems.some(item => item.selected);
};

export const selectedItems = (allItems) => {
    return allItems.filter(item => item.selected);
};