import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import TimeAgo from "react-timeago/lib";
import {LockClock} from "mdi-material-ui";
import {prefixWithPublicUrl} from "../../../utils/helper-functions";


const useStyles = makeStyles(theme => ({
    primaryHeading: {
        textTransform: "none",
        lineHeight: "inherit"
    },
    secondaryHeading: {
        alignSelf: 'center',
        marginLeft: 'auto'
    },
    awaiting: {
        color: "rgb(161, 164, 157)",
        backgroundColor: "rgb(250 254 245)",
        minHeight: 56,
        '& div.MuiAlert-icon': {
            color: 'rgb(161, 164, 157)',
            backgroundColor: 'rgb(250 254 245)',
        },
        '& div.MuiAlert-standardInfo': {
            color: 'rgb(161, 164, 157)',
            backgroundColor: 'rgb(250 254 245)',
        },
        '& > .MuiExpansionPanelSummary-content.Mui-expanded': {
            margin: '12px 0',
        },
    },
    alert: {
        padding: 0
    },
    details: {
        padding: '16px 0 8px 8px'
    },
}));


const Awaiting = (props) => {
    const classes = useStyles();
    const jobState = props.jobState;
    const enqueuedIcon = <LockClock />

    return (
        <Accordion>
            <AccordionSummary
                className={classes.awaiting}
                id="pending-panel-header"
                expandIcon={<ExpandMore/>}
                aria-controls="pending-panel-content"
            >
                <Alert className={classes.alert} severity="info" icon={enqueuedIcon}>
                    <Typography className={classes.primaryHeading} variant="h6">
                        Pending
                    </Typography>
                </Alert>
                <Typography className={classes.secondaryHeading}><TimeAgo date={new Date(jobState.createdAt)}/></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.details}>Waiting for job&nbsp;<a href={prefixWithPublicUrl(`/jobs/${jobState.id}`)}>{jobState.id}</a>&nbsp;to finish before enqueueing.</div>
            </AccordionDetails>
        </Accordion>
    )
};

export default Awaiting;