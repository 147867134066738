import React from 'react';
import {useHistory} from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from "@material-ui/core/Box";
import {makeStyles} from '@material-ui/core/styles';
import LoadingIndicator from "../LoadingIndicator";
import JobsTable from "./jobs-table";
import {jobStateToHumanReadableTitle} from "../utils/job-utils";
import VersionFooter from "../utils/version-footer";
import JobsFilterPanel from "./jobs-filter-panel";
import {prefixWithContextPath} from "../../utils/helper-functions";

const useStyles = makeStyles(theme => ({
    content: {
        width: '100%',
    },
    titleBox: {
        marginBottom: '1rem'
    },
}));

const JobsView = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const urlSearchParams = new URLSearchParams(props.location.search);
    const page = urlSearchParams.get('page');
    const jobState = urlSearchParams.get('state') ?? 'ENQUEUED';
    const queuePriority = urlSearchParams.get('priority');
    const itemsPerPage = urlSearchParams.get('itemsPerPage') || 20;
    const [isLoading, setIsLoading] = React.useState(true);
    const [jobPage, setJobPage] = React.useState({total: 0, limit: itemsPerPage, currentPage: 0, items: []});
    const [jobPageWithoutState, setJobPageWithoutState] = React.useState({total: 0, limit: itemsPerPage, currentPage: 0, items: []});

    let sort = 'priority:ASC,updatedAt:ASC';
    switch (jobState.toUpperCase()) {
        case 'SUCCEEDED':
        case 'FAILED':
            sort = 'updatedAt:DESC';
            break;
        default:
    }

    React.useEffect(() => {
        setIsLoading(true);
        const offset = (page) * itemsPerPage;
        const limit = itemsPerPage;
        let apiSearchParams = new URLSearchParams(props.location.search);
        apiSearchParams.set('offset', offset);
        apiSearchParams.set('limit', limit);
        apiSearchParams.set('order', sort);
        apiSearchParams.set('state', jobState);
        fetch(prefixWithContextPath(`/api/jobs?${apiSearchParams.toString()}`))
            .then(res => res.json())
            .then(response => {
                setJobPage(response);
                setIsLoading(false);
                if(response.total < 1) {
                    let apiWithoutStateSearchParams = new URLSearchParams(props.location.search);
                    apiWithoutStateSearchParams.delete('state');
                    apiWithoutStateSearchParams.set("offset", 0);
                    apiWithoutStateSearchParams.set("limit", 20);
                    fetch(prefixWithContextPath(`/api/jobs?${apiWithoutStateSearchParams.toString()}`))
                        .then(res => res.json())
                        .then(response => {
                            setJobPageWithoutState(response);
                        })
                        .catch(error => console.log(error));
                }
            })
            .catch(error => console.log(error));
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, jobState, sort, queuePriority, history.location.key]);

    return (
        <main className={classes.content}>
            <Box my={1} className={classes.titleBox}>
                <Typography id="title" variant="h4">{jobStateToHumanReadableTitle(jobState)}</Typography>
            </Box>
            {isLoading
                ? <LoadingIndicator/>
                :
                <>
                    <JobsFilterPanel />
                    <div>&nbsp;</div>
                    <Paper>
                        <JobsTable jobPage={jobPage} jobPageWithoutState={jobPageWithoutState} jobState={jobState}/>
                    </Paper>
                    <VersionFooter/>
                </>
            }
        </main>
    );
};

export default JobsView;