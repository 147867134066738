import React from "react";
import {useHistory, useLocation} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import LinearProgress from '@material-ui/core/LinearProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Alert from "@material-ui/lab/Alert";
import TimeAgo from "react-timeago/lib";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {Cogs} from "mdi-material-ui";
import JobsTable from "../jobs-table";
import {prefixWithContextPath} from "../../../utils/helper-functions";
import ExpandMore from "@material-ui/icons/ExpandMore";
import LoadingIndicator from "../../LoadingIndicator";

const useStyles = makeStyles(() => ({
    primaryHeading: {
        textTransform: "none",
        lineHeight: "inherit"
    },
    secondaryHeading: {
        alignSelf: 'center',
        marginLeft: 'auto'
    },
    alert: {
        padding: 0
    },
    expansionPanel: {
        flexDirection: 'column',
        padding: 0
    },
    processing: {
        color: "rgb(102, 60, 0)",
        backgroundColor: "rgb(255, 244, 229)",
        minHeight: 56,
    },
    details: {
        padding: '5px 0 24px 0'
    },
    console: {
        boxSizing: 'border-box',
        width: '100%',
        color: '#fff',
        backgroundColor: '#113462',
        padding: '24px 0 24px 24px',
        '& > dl': {
            fontFamily: "'Courier New', Courier, monospace",
            fontSize: '85%',
            margin: '0',
        },
        '& > dl dt': {
            float: 'left',
            clear: 'left',
            width: '200px',
            textAlign: 'right',
            color: '#3885b7',
            margin: '-0.1em 0',
        },
        '& > dl dd': {
            margin: '-0.2em 0 -0.2em 220px'
        },
        '& > dl.WARN dd': {
            color: 'orange'
        },
        '& > dl.ERROR dd': {
            color: 'red'
        }
    }
}));

const ColoredLinearProgress = withStyles({
    root: {
        height: '7px'
    },
    barColorPrimary: {
        backgroundColor: '#78b869'
    },
})(LinearProgress);

const getProgressBar = (job, index) => {
    if (job.metadata && job.metadata['jobRunrDashboardProgressBar-' + (index + 1)]) {
        return job.metadata['jobRunrDashboardProgressBar-' + (index + 1)];
    }
    return null;
}

const ProcessingChildJobs = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const index = props.index;
    const job = props.job;
    const jobState = props.jobState;
    const processingIcon = <Cogs/>
    const urlSearchParams = new URLSearchParams(location.search);
    const page = (urlSearchParams.has('page') ? urlSearchParams.get('page') : 0);
    const state = (urlSearchParams.has(`processing-child-job-state-${index}`) ? urlSearchParams.get(`processing-child-job-state-${index}`) : 'ENQUEUED');
    const itemsPerPage = urlSearchParams.get('itemsPerPage') || 20;

    const [expanded, setExpanded] = React.useState(urlSearchParams.has(`processing-child-job-state-${index}`) || job.jobHistory.length === (index + 1));
    const [value, setValue] = React.useState(state);
    const [isLoading, setIsLoading] = React.useState(true);
    const [childJobPage, setChildJobPage] = React.useState({total: 0, limit: 20, currentPage: 0, items: []});

    React.useEffect(() => {
        const offset = (page) * itemsPerPage;
        const limit = itemsPerPage;
        setIsLoading(true);
        fetch(prefixWithContextPath(`/api/batch-jobs/${job.id}/${state}?offset=${offset}&limit=${limit}`))
            .then(res => {
                if (res.status === 200) {
                    res.json()
                        .then(result => {
                            setChildJobPage(result);
                            setIsLoading(false);
                            // all useRef examples are not working
                            setTimeout(() => document.getElementById(`processing-child-panel-header-${index}`).scrollIntoView(), 5);
                        });
                } else {
                    console.log(`No batch jobs found for state ${state}`);
                    setIsLoading(false);
                }
            })
            .catch(error => console.error(error));
    }, [job.id, page, state, itemsPerPage, index]);

    const progressBar = getProgressBar(job, index);
    const handleChange = () => {
        setExpanded(!expanded);
    };
    const handleTabChange = (event, newValue) => {
        history.replace({search: `?processing-child-job-state-${index}=${newValue}`})
        setValue(newValue);
    };

    return (
        <Accordion name={"processing-child-state"} expanded={expanded} onChange={handleChange}>
            <AccordionSummary
                className={classes.processing}
                id={`processing-child-panel-header-${index}`}
                expandIcon={<ExpandMore/>}
                aria-controls="processing-panel-content"
            >
                <Alert className={classes.alert} severity="warning" icon={processingIcon}>
                    <Typography className={classes.primaryHeading} variant="h6">
                        Processing child jobs
                    </Typography>
                </Alert>
                <Typography className={classes.secondaryHeading}><TimeAgo
                    date={new Date(jobState.createdAt)}/></Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.expansionPanel}>
                {progressBar &&
                <ColoredLinearProgress variant="determinate" value={progressBar.progress}/>
                }
                <div className={classes.details}>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                    >
                        <Tab label="Pending" value="AWAITING"/>
                        <Tab label="Scheduled" value="SCHEDULED"/>
                        <Tab label="Enqueued"  value="ENQUEUED"/>
                        <Tab label="Processing"  value="PROCESSING"/>
                        <Tab label="Succeeded"  value="SUCCEEDED"/>
                        <Tab label="Failed"  value="FAILED"/>
                        <Tab label="Deleted"  value="DELETED"/>
                    </Tabs>
                    <Box p={3}>
                        {isLoading
                            ? <LoadingIndicator/>
                            : <JobsTable key={childJobPage} isLoading={isLoading} jobPage={childJobPage} jobState={jobState.state} />
                        }
                    </Box>
                </div>
            </AccordionDetails>
        </Accordion>
    )
};

export default ProcessingChildJobs;