import React from 'react';
import {Route, Switch} from 'react-router-dom';
import WithSidebar from "../utils/with-sidebar";
import JobView from "../../components/jobs/job-view";
import BatchSidebar from "./sidebar";
import {prefixWithPublicUrl} from "../../utils/helper-functions";
import BatchJobsView from "./batch-jobs-view";

const BatchAdmin = function () {
    const BatchJobs = WithSidebar(BatchSidebar, BatchJobsView);
    const BatchJob = WithSidebar(BatchSidebar, JobView);

    return (
        <Switch>
            <Route path={prefixWithPublicUrl('/batch-jobs')} exact render={props => <BatchJobs {...props} /> } />
            <Route path={prefixWithPublicUrl('/batch-jobs/:id')} render={props => <BatchJob {...props}  /> } />
        </Switch>
    );
};

export default BatchAdmin;